.contactUsHero {
  position: relative;
  background-image: url("../../../../assets/image/pexels-tima-miroshnichenko-6614837.jpg");
  background-size: cover;
  background-position: top;
  height: 50vh;
}

.contactUsHero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.contactUsHero-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.custom-toggler .navbar-toggler-icon {
  width: 1.2rem;
  height: 1.2rem;
}
