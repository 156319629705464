.projectsHero {
  position: relative;
  background-image: url("../../../../assets/image/cytonn-photography-n95VMLxqM2I-unsplash.jpg");
  background-size: cover;
  background-position: center;
  height: 50vh;
}

.projectsHero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.projectsHero-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.custom-toggler .navbar-toggler-icon {
  width: 1.2rem;
  height: 1.2rem;
}
