.image-container {
  width: 350px;
  height: 800px;
  overflow: hidden;
}

.cropped-image {
  width: 100%;
  object-fit: cover;
  object-position: top;
}

.text-capitalize {
  text-transform: capitalize;
}
