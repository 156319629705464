@import "~@fortawesome/fontawesome-free/css/all.css";

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  border: none;
  width: 35px;
  height: 35px;
}

.scroll-to-top.hide {
  display: none;
}
