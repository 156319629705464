.hero-hero {
  position: relative;
  /* background-image: url("../../../../assets/image/pexels-rdne-5922202.jpg"); */
  background-image: url("../../../../assets/image/pexels-essow-k-251295-936722.jpg");
  background-size: cover;
  background-position: top;
  height: 80vh;
}

.hero-hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.hero-hero-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.custom-toggler .navbar-toggler-icon {
  width: 1.2rem;
  height: 1.2rem;
}

.heroImage {
  width: 75%;
  height: 75%;
}
